// export * as shapeProps from './shape-props'; // need a babel stuff
import useMuiNotification from '@hooks/useMuiNotification';
import useFilterPagination from '@hooks/useFilterPagination';

import Payment from 'payment';
export * as columns from './columns';
export * as shapeProps from './shape-props';

export { useMuiNotification, useFilterPagination};

// payments utils
export const clearNumber = (value = "") => {
  return value.replace(/\D+/g, "");
}

export const formatCreditCardNumber = (value) => {
  if (!value) { // Only numbers and spaces
    return value;
  }

  // Ensure that the value contains only digits and spaces. Also replace spaces with empty strings.
  const sanitizedValue = value.replace(/[^\d\s]/g, '').replace(/\s/g, '');
    
  // Limit the value to a maximum of 16 characters (digits only) - Standard for credit cards
  const limitedValue = sanitizedValue.slice(0, 16);
  
  const formattedValue = Payment.fns.formatCardNumber(limitedValue); // Format the value as a credit card number (spaces and stuff)

  return formattedValue;
};

export const formatCVC = (value, prevValue, allValues = {}) => {
  const clearValue = clearNumber(value);
  let maxLength = 4;

  if (allValues.number) {
    const issuer = Payment.fns.cardType(allValues.number);
    maxLength = issuer === "amex" ? 4 : 3;
  }

  return clearValue.slice(0, maxLength);
}

export const formatExpirationDate = (value) => {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

/**
 * V.1.0.0
 * Useful function to display the data of a list of records based on the objects list and how to display them.
 * @param { object } record - The record to get the display value from
 * @param { object } columnInfo  - The column info to know how to display this "cell" (must have AT least accessor property)
 * @param { number } index - Index of the record on caller component
 * @param { object } utils - Utils functions passed to accessor when it is a function
 * @returns return a value or component depending on the columnInfo properties
 */
export const renderAccessorValue = ({ record, columnInfo, index = 0, utils = {} }) => {
  const { accessor } = columnInfo;
  if (typeof (accessor) == "string") { // Normal render using accessor/attribute provided
    return record[accessor]; // record['attribute']
  } else { // Function
    return accessor(record, utils); // accessor as function like (record) => fullName(record)
  }
}

/**
 * V.1.0.0
 * Build FormData from the values of a JS Object, useful when files need to be sent as normal JSON doesn't support blobs
 * Receives at least parentName and object and return final FormData to be used.
 * !!!IMPORTANT NOTE!!! Form Data cannot set null values, as it turn them on "null" strings. So take that into account when updating records.
 * Example: buildFormData({
 *  parentName: "user",
 *  values: {
 *     name: "John",
 *     tags: ["1", "2"],
 *     avatar: Blob(filename: "xxx.jpg", type: "image/jpeg", ...),
 *     newDocumentFile: "Blob(...)"
 *  }
 *  fileAliases: { avatar: "avatar", newDocumentFile: "attachment" }
 * }) => FormData()
 * @param {String} parentName - Name of the upper level where fields will be nested on form.
 * @param {Object} values - The object to get the fields from
 * @param {Object} fileAliases - When working with files set an object which will translate file fields into the submition field with the file data, this allows form data to have file handled with different names and be sent to backend with final field name
 * @param {FormData} formData - Existing FormData to update else an empty one will be created
 */
export const buildFormData = ({ parentName, values, fileAliases = null, formData = null,  }) => {
  if(!formData){
    formData = new FormData();
  }

  for(let fieldName of Object.keys(values)){ // write link attributes
    if(values[fieldName] != null){ // Do not write "nulls"
      if(fileAliases && fileAliases[fieldName] ){ // File handler i.e. { "formUploadImage": "avatar" } => formData.append('user.avatar', file, file.name)
        const formFieldName = fileAliases[fieldName];
        formData.append(`${parentName}[${formFieldName}]`, values[fieldName], values[fieldName].name);
      }
      else if( Array.isArray(values[fieldName]) ){ // Simple Array handler i.e. object["tags"].forEach(tag => formData.append("user.tags")][]`, tag))
        values[fieldName].forEach( (item) => formData.append(`${parentName}[${fieldName}][]`, item)  )
      }
      else{ // Any kind of simple data i.e. 
        formData.append(`${parentName}[${fieldName}]`, values[fieldName]) // i.e. "section[app_links_attributes][0][title]"
      }
    }
  }

  return formData;
}

// Generate image url on client for file or display placeholder
const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
export const getFileURL = (file) => {
  if(file.url){
    return file.url
  }
  else{
    return validImageTypes.indexOf(file.type) > 0 ? window.URL.createObjectURL(file) : 'https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304'
  }
}