import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Card, CardActions, CardContent, TextField, Button } from '@mui/material';

import AppPage from 'components/shared/AppPage';
import axios from 'utils/axios-rails';
import useMuiNotification, { AppNotification } from '@hooks/useMuiNotification';

const propTypes = {
  submitURL: PropTypes.string.isRequired,
}

const defaultProps = {

}

const GiftCardFromBrightpearl = (props) => {
  const [loading, setLoading] = useState(false);
  const [sendNotification, notificationProps] = useMuiNotification();
  const [brightpearlId, setBrightpearlId] = useState('');

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await axios.post(props.submitURL, { order_id: brightpearlId });
      const message = response.data.message;
      sendNotification({ message, severity: 'success' });
    } catch (error) {
      console.log("Error", error)
      const message = error.response?.data?.message || 'Ocurrio un error de conexión';
      sendNotification({ message, severity: 'error' });
    }
    setLoading(false);
  }

  const formDisabled = loading || !brightpearlId;

  return (
    <AppPage title="Generar desde Brightpearl">
      <AppNotification {...notificationProps} />
      <Card>
        <CardContent>
          <form onSubmit={handleSubmit}>
            <TextField 
              fullWidth
              type='number'
              label="Brightpearl ID"
              value={brightpearlId}
              onChange={(e) => setBrightpearlId(e.target.value)}
            />
          </form>
        </CardContent>
        <CardActions>
          <Button 
            fullWidth
            onClick={handleSubmit} 
            variant='contained'
            disabled={formDisabled}
          >
            Generar
          </Button>
        </CardActions>
      </Card>
    </AppPage>
  )
};

GiftCardFromBrightpearl.propTypes = propTypes;
GiftCardFromBrightpearl.defaultProps = defaultProps;

export default GiftCardFromBrightpearl;