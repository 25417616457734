import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { 
  Card, CardActions, CardContent, Button,
  CircularProgress, Typography
} from '@mui/material';

import AppPage from 'components/shared/AppPage';
import UploadButton from 'components/shared/form/UploadButton';
import axios from 'utils/axios-rails';
import useMuiNotification, { AppNotification } from '@hooks/useMuiNotification';

const propTypes = {
  submitURL: PropTypes.string.isRequired,
}

const defaultProps = {

}

const GiftCardFromFile = (props) => {
  const [loading, setLoading] = useState(false);
  const [sendNotification, notificationProps] = useMuiNotification();

  const [importFile, setImportFile] = useState(null);
  const [errors, setErrors] = useState([]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const formData = new FormData;
      formData.append("file", importFile);
      const response= await axios.post(props.submitURL, formData);

      const message = response.data.message;
      sendNotification({ message, severity: 'success' });
      setImportFile(null);
      setErrors([]);
    } catch (error) {
      console.log("Error", error)
      const message = error.response?.data?.message || 'Ocurrio un error de conexión';
      sendNotification({ message, severity: 'error' });

      const errors = error.response?.data?.errors || [];
      setErrors(errors);
    }
    setLoading(false);
  }

  const formDisabled = loading || !importFile;

  return (
    <AppPage 
      title="Generar desde Archivo"
      primaryAction={{
        content: "Archivo muestra",
        url: '/samples/sample_gift_cards_from_file.xlsx',
      }}
    >
      <AppNotification {...notificationProps} />
      <Card>
        <CardContent>
          <div className="flex justify-between">
            <Typography color="GrayText" className='mb12'>
              Al subir el archivo se generaran las gift cards para los clientes especificados en el mismo. Cada línea generara un gift card.
              <br/>
              <strong>El archivo debe contener los headers 'email', 'amount', 'notes'</strong>
            </Typography>

            <CircularProgress size={24} hidden={ !loading } />
          </div>

          <UploadButton 
            onUpload={ setImportFile }
            helpText="Solo se aceptan archivos .xlsx y .xls"
          />

        { errors.map((error, index) => (
          <Typography key={ index } color="error">
            { error }
          </Typography>
        ))}
        </CardContent>
        <CardActions>
          <Button 
            fullWidth
            onClick={handleSubmit} 
            variant='contained'
            disabled={formDisabled}
          >
            Generar
          </Button>
        </CardActions>
      </Card>
    </AppPage>
  )
};

GiftCardFromFile.propTypes = propTypes;
GiftCardFromFile.defaultProps = defaultProps;

export default GiftCardFromFile;