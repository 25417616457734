import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Page from 'shared/AppPage';
import UploadButton from 'components/shared/form/UploadButton';
import DropdownSelect from 'shared/form/DropdownSelect';

import useMuiNotification, { AppNotification } from '@hooks/useMuiNotification';
import axios from 'utils/axios-rails';

import { TextField, Button, Typography, CircularProgress, Card, CardActions } from '@mui/material';

const propTypes = {
  submitURL: PropTypes.string.isRequired,
  fieldOptions: PropTypes.array.isRequired,
  exportURL: PropTypes.string.isRequired,
}

const defaultProps = {

}

const IngramReferenceValueImport = (props) => {
  const [sendNotification, notificationProps] = useMuiNotification();

  const [importFile, setImportFile] = useState(null);
  const [selectedField, setSelectedField] = useState(null);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const handleFieldChange = (name, value) => {
    setSelectedField(value);
  }

  const handleSubmit = async () => {
    setLoading(true);
    setErrors([]);

    try {
      const formData = new FormData;
      formData.append("file", importFile);
      formData.append("field", selectedField);
      const { data } = await axios.post(props.submitURL, formData);
      console.log("D", data);
      if(data.success){
        sendNotification({ message: "Todos los valores fueron actualizados correctamente", severity: "success" })
        setImportFile(null);
      }
      else{
        sendNotification({ message: "Algunos valores no se pudieron actualizar, revise los errores e intente nuevamente", severity: "error" })
        setErrors(data.errors);
      }
    } catch (error) {
      console.error("E", error);
      sendNotification({ message: "Ocurrio un error de conexión, revise los datos e intente nuevamente", severity: "error" })
    }
    setLoading(false);
  }

  return (
    <Page title="Import Reference Values">
      <AppNotification
        {...notificationProps}
      />
      <div className="flex justify-between">
        <Typography color="GrayText" className='mb12'>
          Al subir el archivo se guardaran las traducciones para los valores de referencia proporcionados.
          <br/>
          <strong>El archivo debe contener los headers 'code' y 'translation'</strong>
        </Typography>

        <CircularProgress size={24} hidden={ !loading } />
      </div>

      <div>
        <DropdownSelect
          label="Campo"
          containerClass="width-full"
          options={ props.fieldOptions }
          onSelect={ handleFieldChange }
          initialValue=""
        />

        <UploadButton 
          onUpload={ setImportFile }
          helpText="Solo se aceptan archivos .xlsx y .xls"
        />

        <Button 
          className='mt12'
          fullWidth variant="contained" 
          color='primary' 
          onClick={ handleSubmit } 
          disabled={ loading || !importFile || !selectedField }>
          Cargar
        </Button>

        <Button
          href={ `${props.exportURL}?field=${selectedField}` }
          target="_blank"
          className='mt12'
          fullWidth variant="contained" 
          color='secondary' 
          disabled={ loading || !selectedField }
        >
          Exportar Valores existentes
        </Button>
      </div>

      { errors.map((error, index) => (
        <Typography key={ index } color="error">
          { error }
        </Typography>
      ))}
    </Page>
  )
};

IngramReferenceValueImport.propTypes = propTypes;
IngramReferenceValueImport.defaultProps = defaultProps;

export default IngramReferenceValueImport;