import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import Page from 'shared/AppPage';
import AppModal from 'components/shared/AppModal';

import { 
  Paper,
  TableContainer, Table, TableHead, TableBody, TableRow, TableCell,
  IconButton, Chip,
  Pagination, TextField, CircularProgress,
  Avatar
} from '@mui/material';

import ShowIcon from '@mui/icons-material/Visibility';

import lodashGet from 'lodash/get';
import debounce from 'lodash/debounce';

import IngramDetailModal from './IngramDetailModal';
import DropdownSelect from 'shared/form/DropdownSelect';

import useFilterPagination from '@hooks/useFilterPagination';

const propTypes = {
  records: PropTypes.array.isRequired,
  ingramLinkedDataFileAttributes: PropTypes.array.isRequired, // Attributes on IngramTitle model related to linked data files. i.e. annotations
  filterUrl: PropTypes.string.isRequired,
  total_pages: PropTypes.number.isRequired,

  fileSourceOptions: PropTypes.array.isRequired,
}

const defaultProps = {

}

const IngramTitlesIndex = (props) => {
  const [searchQuery, setSearchQuery] = useState('');

  const detailModalRef = useRef(null);
  // const [selectedTitle, setSelectedTitle] = useState(props.records.find((title) => title.ean_id === '9780006387145'));
  const [selectedTitle, setSelectedTitle] = useState(null);
  const { records, loading, handleFilterChange, handlePageChange, pagination } = useFilterPagination(props.filterUrl, {
    initialFilters: {
      query: '',
      fileSource: '',
      duplicatedTitle: null,
    },
    initialRecords: props.records,
    initialTotalPages: props.total_pages,
  });

  console.log("IngramTitlesIndex", props);

  const handleSelectTitle = (selectedItem) => {
    setSelectedTitle(selectedItem);
    detailModalRef.current.open();
  }

  const buildRow = (title) => {
    const cellKeys = ['fileSource', 'eanId', 'title', 'desirability', 'metadata.productType'];
    
    return (
      <TableRow key={title.eanId}>
        <TableCell>
          <IconButton onClick={ () => handleSelectTitle(title) }>
            <ShowIcon />
          </IconButton>
        </TableCell>
        <TableCell>
          <Avatar alt="Remy Sharp" src={ title.imageUrl } />
        </TableCell>
        { cellKeys.map((key) => (
          <TableCell key={key}>{ lodashGet(title, key) }</TableCell>
        ))}
        <TableCell>
          <div className='flex flex-wrap'>
            { props.ingramLinkedDataFileAttributes.map((key) => {
              if(!!lodashGet(title, key)){
                return <Chip key={ key } label={ key } color='primary' className='mr4 mb4' />
              }
              else{
                return null;
              }
            })}
          </div>
        </TableCell>
      </TableRow>
    )
  }

  const submitFilterQuery = useCallback(debounce( (query) => {
    handleFilterChange('query', query);
  }, 800), []);

  const handleSearchQueryChange = useCallback( (e) => {
    setSearchQuery(e.target.value);
    submitFilterQuery(e.target.value);
  }, []);

  // if(!!selectedTitle){
  //   console.log("Stuff", omit(Object.keys(selectedTitle), ['metadata']));
  // }

  const duplicatedTitleFilterOptions = [
    { value: null, label: 'All' },
    { value: true, label: 'True' },
    { value: false, label: 'False' },
  ]

  return (
    <Page title="Ingram Titles DEMO">
      <div className="mb12">
        <TextField
          fullWidth
          label="Search"
          placeholder='EAN, ISBN, Title, Shopify ID, Brightpearl ID, etc'
          className='mh4'
          variant="outlined"
          
          value={ searchQuery }
          onChange={ handleSearchQueryChange }
        />

        <div className="ph4 pv8">
          <DropdownSelect
            label="Source"
            containerClass="width-full"
            options={ props.fileSourceOptions }
            onSelect={ (_name, value) => handleFilterChange('fileSource', value) }
            initialValue=""
          />
        </div>
       
        <div className="ph4 pv8">
          <DropdownSelect
            label="Duplicated Title"
            containerClass="width-full"
            options={ duplicatedTitleFilterOptions }
            onSelect={ (_name, value) => handleFilterChange('duplicatedTitle', value) }
            initialValue={null}
          />
        </div>
      </div>
      
      <Pagination 
        className='mv20 flex justify-center'
        page={ pagination.page }
        onChange={ (event, value) => pagination.handlePageChange(value) }
        count={ pagination.totalPages } 
      />

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                { loading && <CircularProgress size={30} /> }
              </TableCell>
              <TableCell></TableCell>{/* Image */}
              <TableCell>Source</TableCell>
              <TableCell>EAN</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Desire</TableCell>
              <TableCell>Product Type</TableCell>
              <TableCell>Linked Files</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { records.map((title) => buildRow(title)) }
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination 
        className='mv20 flex justify-center'
        page={ pagination.page }
        onChange={ (event, value) => pagination.handlePageChange(value) }
        count={ pagination.totalPages } 
      />

      <AppModal
        ref={ detailModalRef }
        title={ selectedTitle?.title }
        headerProps={{
          subheader: `EAN: ${ selectedTitle?.eanId }`
        }}
      >
        <IngramDetailModal 
          selectedTitleId={ selectedTitle?.id } 
          ingramLinkedDataFileAttributes={ props.ingramLinkedDataFileAttributes }
          filterUrl={ props.filterUrl }
        />
      </AppModal>
    </Page>
  )
};

IngramTitlesIndex.propTypes = propTypes;
IngramTitlesIndex.defaultProps = defaultProps;

export default IngramTitlesIndex;