import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Container, Typography, Button, Breadcrumbs, Link } from '@mui/material';
import ButtonWithActions from './ButtonWithActions';

import I18nContextProvider from '@hooks/I18nContext';

import { shapeProps } from 'utils';

const propTypes = {
  title: PropTypes.string.isRequired,
  titleContained: PropTypes.bool,
  container: PropTypes.bool,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string, // Will be set as primary text if not present
    }).isRequired,
  ),

  primaryAction: PropTypes.oneOfType([ PropTypes.node, shapeProps.primaryActionProp ]) // React element or primaryActionProp(Shape of button with actions)
}

const defaultProps = {
  container: true,
}

// Not related to AppPage model. TODO rename to PageContainer or PageLayout
const AppPage = (props) => {

  const Heading = (
    <div className="flex justify-between">
      <Typography variant='h4'>
        { props.title }
      </Typography>
      <div>
        { props.primaryAction && React.isValidElement(props.primaryAction) && props.primaryAction }
        { props.primaryAction && !React.isValidElement(props.primaryAction) && <ButtonWithActions {...props.primaryAction} /> }
      </div>
    </div>
  )

  const Content = (
    <I18nContextProvider>
      <>
        { props.breadcrumbs &&
          <Breadcrumbs aria-label="breadcrumb" className='mb8'>
            { props.breadcrumbs.map((item, index) => {
              if(item.url){
                return <Link key={index} underline="hover" color="inherit "href={item.url}>{item.label}</Link>
              }
              else{
                return <Typography key={index} color="text.primary">{item.label}</Typography>
              }
            }) }
          </Breadcrumbs>
        }

        { props.titleContained 
          ? <Container>{ Heading }</Container> 
          : Heading 
        }

        <div className="mt12">
          { props.children }
        </div>
      </>
    </I18nContextProvider>
  )

  if(!props.container){
    return(
      <div className='app-page p16'>
        { Content }
      </div>
    )
  }
  else{
    return (
      <Container className='mv12' maxWidth="xl">
        { Content }
      </Container>
    )
  }
};

AppPage.propTypes = propTypes;
AppPage.defaultProps = defaultProps;

export default AppPage;