import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { 
  Card, CardContent, Container,
  Typography, TextField, Button,
  FormControl, FormControlLabel, FormLabel, RadioGroup, Radio
 } from '@mui/material';

 import { AppNotification, useMuiNotification } from '@hooks/useMuiNotification';
 import { useI18n } from '@hooks/I18nContext';

import { useQuery, useLazyQuery, gql } from '@apollo/client';
import adminClient from 'api/apollo-client';

 import Page from 'shared/AppPage';
 import AppTable from 'shared/AppIndex';

 import { useFormik } from 'formik';

const propTypes = {

}

const defaultProps = {

}

const UniversalSkuSearch = (props) => {
  const [sendNotification, notificationProps] = useMuiNotification();
  const I18n = useI18n();
  const [foundItems, setFoundItems] = useState([]);

  const { data: skuTypeData, loading: skuTypeLoading } = useQuery(SKU_TYPE_CATALOG_QUERY, {
    client: adminClient,
  });

  const [submitSearch, { loading }] = useLazyQuery(UNIVERSAL_SKU_SEARCH_QUERY, {
    client: adminClient,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const items = data.toolsUniversalSkuSearch;
      setFoundItems(items);
      if (items.length === 0) {
        sendNotification({ message: 'No se encontraron resultados', severity: 'info' });
      }
      formik.setSubmitting(false);
    },
    onError: (error) => {
      sendNotification({ message: 'Error de conexión', severity: 'error' });
      formik.setSubmitting(false);
    }
  });

  const formik = useFormik({
    initialValues: {
      skuType: skuTypeData?.universalSkuTypeEnum?.enumValues[0].name || '',
      sku: ''
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      setFoundItems([]);
      submitSearch({ 
        variables: {
          input: formik.values
        } 
      });
    }
  });

  const TABLE_COLUMNS = [
    { header: "SKU Global", accessor: "globalSku" },
    { header: "Supplier SKU", accessor: ({ supplierSku, highlight }) => (
      <span style={{ fontWeight: highlight ? 'bold' : 'normal' }}>
        { supplierSku }
      </span> 
    )},
    { header: "Fecha Actualización", accessor: ({ updatedAt }) => I18n.l("time.formats.app_datetime", updatedAt)},
  ]

  const canSubmit = !formik.isSubmitting && !loading && !!formik.values.sku && !!formik.values.skuType;

  return (
    <Page title={ I18n.t('app.links.universal_sku_search') } titleContained>
      <AppNotification {...notificationProps} />

      <Container>
        <Card>
          <CardContent>
            <form onSubmit={formik.handleSubmit}>
              <FormControl>
                <FormLabel id="sku-type-radio">SKU</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="sku-type-radio"
                  name="skuType"
                  value={formik.values.skuType}
                  onChange={formik.handleChange}
                >
                  { skuTypeData?.universalSkuTypeEnum?.enumValues.map((enumItem) => (
                    <FormControlLabel
                      key={enumItem.name}
                      label={ I18n.t(`api.enums.universal_sku_type.${enumItem.name}`) }
                      value={enumItem.name}
                      control={<Radio />}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <TextField
                name='sku'
                label="SKU Global, Supplier SKU"
                fullWidth
                variant="outlined"
                value={ formik.values.sku }
                onChange={ formik.handleChange }
              />

              <Button
                fullWidth
                className='mt20'
                color='primary'
                variant='contained'
                onClick={ formik.handleSubmit }
                disabled={ !canSubmit }
              >
                BUSCAR
              </Button>
            </form>

            { foundItems.length > 0 &&
              <div className="mt20">
                <AppTable
                  keyAccesor='supplierSku'
                  items={foundItems}
                  columnsData={TABLE_COLUMNS}
                />
              </div>
            }
          </CardContent>
        </Card>
      </Container>
    </Page>
  )
};

const SKU_TYPE_CATALOG_QUERY = gql`
query{
  universalSkuTypeEnum: __type(name: "UniversalSkuTypeEnum") {
    name
    kind
    enumValues {
      name
    }
  }
}
`;

const UNIVERSAL_SKU_SEARCH_QUERY = gql`
query($input: UniversalSkuSearchInput!) {
  toolsUniversalSkuSearch(input: $input) {
    globalSku
    shopifyVariantId
    supplierSku
    updatedAt
    highlight
  }
}
`


UniversalSkuSearch.propTypes = propTypes;
UniversalSkuSearch.defaultProps = defaultProps;

export default UniversalSkuSearch;