import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useMuiNotification, AppNotification } from '@hooks/useMuiNotification';
import { useI18n } from '@hooks/I18nContext';

import { useLazyQuery, gql } from '@apollo/client';
import adminClient from 'api/apollo-client';

import Page from 'shared/AppPage';

import { useFormik, FormikProvider } from 'formik';
import * as yup from 'yup';

import { 
  Card, CardContent, Container,
  Typography, Button,
  FormControl, FormControlLabel, FormLabel, RadioGroup, Stack
 } from '@mui/material';

 import TagsInput from 'react-tagsinput';

 import AppTextField from 'components/shared/form/AppTextField';

const propTypes = {

}

const defaultProps = {

}

const ShopifyProductsMetaobjectsExport = (props) => {
  const [sendNotification, notificationProps] = useMuiNotification();
  const I18n = useI18n();

  const [generateExport, { loading }] = useLazyQuery(GENERATE_EXPORT, {
    client: adminClient,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const success = data.toolsShopifyProductsExport.success;
      if (success) {
        sendNotification({ message: 'El export se enviará a los emails solicitados', severity: 'success' });
      } 
      else {
        formik.setErrors(userErrorsToFormik(data.toolsShopifyProductsExport.errors));
        sendNotification({ message: 'Ocurrio un error', severity: 'error' });
      }
    },
    onError: (error) => {
      sendNotification({ message: 'Error de conexión', severity: 'error' });
      formik.setSubmitting(false);
    }
  });

  const formik = useFormik({
    initialValues: {
      recipients: [],
      sku: '', // i.e. 'C11CG86402'
      title: '', // i.e. 'Epson Impresora'
      tag: '', // i.e. tipo:something
      productType: '', // i.e 'Epson
    },
    onSubmit: (values) => {
      generateExport({ 
        variables: {
          input: formik.values
        } 
      });
    },
    validationSchema: VALIDATION_SCHEMA
  });

  console.log("ShopifyProductsMetaobjectsExport", formik.values);

  return (
    <FormikProvider value={formik}>
      <Page title={ I18n.t('app.links.shopify_products_export') } titleContained>
        <AppNotification {...notificationProps} />

        <Container>
          <Card>
            <CardContent>

              <Typography color='GrayText' className='mb-2'>
                Es requerido ingresar al menos 1 email para recibir el export<br/>
                Los filtros son opcionales
              </Typography>

              <form onSubmit={formik.handleSubmit}>
                <TagsInput
                  value={ formik.values.recipients }
                  onlyUnique
                  addKeys={[188, 9]} // Comma, Tab
                  inputProps={{
                    placeholder: "Agregar Email",
                  }}
                  addOnPaste
                  pasteSplit={
                    (data) => {
                      return data.split(',').map(d => d.trim())
                  }}
                  onChange={ (tagsList) => formik.setFieldValue("recipients", tagsList)}
                  className='react-tagsinput mb-2'
                />

                <Stack direction='row' justifyContent='space-between' className='mb-2' columnGap={2}>
                  <AppTextField
                    name='sku'
                    label="SKU"
                    fullWidth
                  />
                  <AppTextField
                    name='title'
                    label="Título"
                    fullWidth
                  />
                </Stack>

                <Stack direction='row' justifyContent='space-between' className='mb-2' columnGap={2}>
                  <AppTextField
                    name='tag'
                    label="Tag"
                    fullWidth
                  />
                  <AppTextField
                    name='productType'
                    label="Product Type"
                    fullWidth
                  />
                </Stack>

                <Button
                  fullWidth
                  className='mt20'
                  color='primary'
                  variant='contained'
                  onClick={ formik.handleSubmit }
                  disabled={ !formik.isValid }
                >
                  Enviar
                </Button>
              </form>
            </CardContent>
          </Card>
        </Container>
      </Page>
    </FormikProvider>
  )
};

ShopifyProductsMetaobjectsExport.propTypes = propTypes;
ShopifyProductsMetaobjectsExport.defaultProps = defaultProps;

const { string } = yup;

// https://github.com/jquense/yup/blob/master/src/locale.ts
yup.setLocale({
  // use constant translation keys for messages without values
  mixed: {
    default: 'field_invalid',
    required: "es requerido",
  },
});

const VALIDATION_SCHEMA = yup.object({
  recipients: yup.array().min(1, "Debes agregar al menos un email").required(),
  sku: string(),
  title: string(),
  tag: string(),
  productType: string(),
});

const GENERATE_EXPORT = gql`
query($input: ShopifyProductsExportInput!) {
  toolsShopifyProductsExport(input: $input) {
    success
    errors{
      field
      message
    }
  }
}
`;

export default ShopifyProductsMetaobjectsExport;