import React, { createContext, useContext } from 'react';
import { I18n } from 'i18n-js';
import translations from '../locales.json';

const i18n = new I18n(translations);
i18n.defaultLocale = "es";
i18n.locale = 'es'; // Set your desired locale

const I18nContext = React.createContext(i18n);

const useI18n = () => useContext(I18nContext);

const I18nContextProvider = ({ children }) => (
  <I18nContext.Provider value={ i18n }>
    { children }
  </I18nContext.Provider>
)

export {
  useI18n,
}

export default I18nContextProvider;